import PropTypes from 'prop-types';
import { forwardRef, ReactNode } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Box, useTheme } from '@mui/material';

interface ScrollbarProps {
  className?: string;
  children?: ReactNode;
}

const Scrollbar = forwardRef<Scrollbars, ScrollbarProps>(
  ({ className, children, ...rest }, ref) => {
    const theme = useTheme();

    return (
      <Scrollbars
        ref={ref}
        autoHide
        renderThumbVertical={() => {
          return (
            <Box
              sx={{
                width: 5,
                background: `${theme.colors.alpha.black[10]}`,
                borderRadius: `${theme.general.borderRadiusLg}`,
                transition: `${theme.transitions.create(['background'])}`,

                '&:hover': {
                  background: `${theme.colors.alpha.black[30]}`
                }
              }}
            />
          );
        }}
        {...rest}
      >
        {children}
      </Scrollbars>
    );
  }
);

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Scrollbar;
