import { lazy } from 'react';
import LazyLoader from 'src/components/LazyLoader';

const ThreatActors = LazyLoader(lazy(() => import('src/content/ThreatActors')));

const ThreatActorDetails = LazyLoader(
  lazy(() => import('src/content/ThreatActors/ThreatActorDetails'))
);

export const threatActorRoutes = [
  {
    path: 'threat-actors',
    element: <ThreatActors />
  },
  {
    path: 'threat-actors/:threatActorId',
    element: <ThreatActorDetails />
  }
];
