import { useContext, useRef } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  Divider,
  Drawer,
  IconButton,
  alpha,
  darken,
  lighten,
  styled,
  useTheme
} from '@mui/material';

import { ArrowDownward } from '@mui/icons-material';
import Scrollbars from 'react-custom-scrollbars-2';
import Logo from 'src/components/LogoSign';
import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  const scrollbarRef = useRef<Scrollbars>(null);

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background:
            theme.palette.mode === 'dark'
              ? alpha(lighten(theme.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.black[100], 0.3),
          boxShadow: 'none'
        }}
      >
        <Scrollbar ref={scrollbarRef}>
          <Box mt={2}>
            <Logo />
          </Box>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              background: alpha(theme.colors.alpha.trueWhite[10], 0) // transparent
            }}
          />
          <SidebarMenu />
        </Scrollbar>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          pt={1}
          onClick={() => {
            if (scrollbarRef.current) {
              const scrollbars = scrollbarRef.current;
              const start = scrollbars.getScrollTop();
              const end =
                scrollbars.getScrollHeight() - scrollbars.getClientHeight();
              let startTime: number | null = null;
              const duration = 250; // Adjust for desired smoothness

              const animateScroll = (timestamp: number) => {
                if (!startTime) startTime = timestamp;
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / duration, 1);
                const easeOut = progress * (2 - progress);
                scrollbars.scrollTop(start + (end - start) * easeOut);

                if (elapsed < duration) {
                  requestAnimationFrame(animateScroll);
                }
              };

              requestAnimationFrame(animateScroll);
            }
          }}
        >
          <IconButton color="secondary" size="small">
            <ArrowDownward />
          </IconButton>
        </Box>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? alpha(lighten(theme.header.background, 0.1), 0.5)
                : darken(theme.colors.alpha.black[100], 0.3),
            boxShadow: 'none'
          }}
        >
          <Scrollbar>
            <Box mt={2}>
              <Logo />
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
