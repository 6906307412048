import { assetType } from 'src/models/asset';
import { threatStatus, threatType } from 'src/models/threat';

export const THREAT_TYPES_TO_GROUP = [
  threatType.CVE,
  threatType.INFORMATION_LEAKAGE
];

export const ACCESS_TOKEN_AUTH = 'ACCESS_TOKEN_AUTH';
export const REFRESH_TOKEN_AUTH = 'REFRESH_TOKEN_AUTH';

export enum AssetTypes {
  DOMAIN = 'domain',
  IP_ADDRESS = 'ip_address',
  SUBDOMAIN = 'subdomain',
  PORT = 'port',
  IP_SUBNET = 'ip_subnet',
  KEYWORD = 'keyword',
  USERNAME = 'username'
}

export enum ThreatTypeRoute {
  CVE = 'cve',
  MALWARE = 'malware',
  BRAND = 'brand',
  PHISHING = 'brand/phishing',
  DOMAIN_TAKEOVER = 'brand/domain-takeover',
  INFRASTRUCTURE = 'infrastructure',
  DARK_WEB = 'dark-web',
  INFORMATION_LEAKAGE = 'information-leakage',
  SENSITIVE_DATA_LEAK = 'information-leakage/sensitive-data-leak',
  BREACH = 'information-leakage/breach',
  POTENTIAL_MALWARE_DISTRIBUTION = 'malware/distribution',
  INSECURE_PORT = 'insecure-port',
  TLS_CERTIFICATE = 'tls-certificate',
  DOMAIN_EXPIRY = 'domain-expiry'
}

export interface LabelValuePair {
  label: string;
  value: any;
}
export interface ThreatFilters {
  assets: string[];
  assetsSearch: string;
  setAssetsSearch: (query: string) => void;
  selectedAssets: string[];
  setSelectedAssets: (assets: string[]) => void;
  selectedTypes: string[];
  setSelectedTypes: (types: string[]) => void;
  selectedSeverities: string[];
  setSelectedSeverities: (severities: string[]) => void;
  selectedThreatStatus: string;
  setSelectedThreatStatus: (threatStatus: string) => void;
  selectedGroupThreats: boolean;
  setSelectedGroupThreats: (groupThreats: boolean) => void;
}

export interface Pagination {
  page: number;
  pageSize: number;
  count: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setCount?: (pageSize: number) => void;
}

export interface Search {
  searchQuery: string;
  setSearchQuery: (searchQuery) => void;
}

export interface QueryParams {
  page?: number;
  pageSize?: number;
  search?: string;
  ordering?: string;
}

export interface AssetQueryParams extends QueryParams {
  processingIgnored?: boolean;
  assetType?: string[];
}

export interface ThreatQueryParams extends QueryParams {
  severity?: string[];
  assetId?: number;
  threatType?: string[];
  threatStatus?: threatStatus;
}

export interface Ordering {
  orderingQuery: string;
  isActive: (name: string) => boolean;
  handleColumnClick: (name: string) => void;
  getDirection: (name: string) => 'asc' | 'desc';
}

export interface Selection {
  selected: number[];
  selectedObjects: any[];
  handleSelectAllClick: (e) => void;
  handleSelectClick: (object: any) => void;
  isSelected: (id: number) => boolean;
}

export const calendarMonths = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12'
};

export const ASSET_TYPE_SUB_ASSETS = {
  [assetType.IPAdress]: {
    POSSIBLE: false
  },
  [assetType.Domain]: {
    POSSIBLE: true,
    DEFAULT: true,
    LABEL: 'Automatically monitor related IP addresses'
  },
  [assetType.Keyword]: {
    POSSIBLE: false
  },
  [assetType.IPSubnet]: {
    POSSIBLE: true,
    DEFAULT: true
  },
  [assetType.VIPEmail]: {
    POSSIBLE: false
  }
};

export const ASSET_TYPE_SCANS = {
  [assetType.IPAdress]: {
    PRICING_PLAN_LIMIT: 'ipAddressLimit',
    COUNTS_PROPERTY: 'monitoredIpAddresses',
    SCANS: [
      {
        SCAN: 'MALWARE',
        PRICING_PLAN_LIMIT: 'ipAddressMalwareLimit',
        COUNTS_PROPERTY: 'monitoredIpAddressesMalware'
      },
      {
        SCAN: 'PHISHING',
        PRICING_PLAN_LIMIT: 'ipAddressPhishingLimit',
        COUNTS_PROPERTY: 'monitoredIpAddressesPhishing'
      },
      {
        SCAN: 'DARK_WEB',
        PRICING_PLAN_LIMIT: 'ipAddressDarkWebLimit',
        COUNTS_PROPERTY: 'monitoredIpAddressesDarkWeb'
      },
      {
        SCAN: 'BREACH',
        PRICING_PLAN_LIMIT: 'ipAddressBreachLimit',
        COUNTS_PROPERTY: 'monitoredIpAddressesBreach'
      },
      {
        SCAN: 'INFO_LEAKAGE',
        PRICING_PLAN_LIMIT: 'ipAddressInfoLeakageLimit',
        COUNTS_PROPERTY: 'monitoredIpAddressesInfoLeakage'
      }
    ]
  },
  [assetType.Domain]: {
    PRICING_PLAN_LIMIT: 'domainLimit',
    COUNTS_PROPERTY: 'monitoredDomains',
    SCANS: [
      {
        SCAN: 'MALWARE',
        PRICING_PLAN_LIMIT: 'domainMalwareLimit',
        COUNTS_PROPERTY: 'monitoredDomainsMalware'
      },
      {
        SCAN: 'PHISHING',
        PRICING_PLAN_LIMIT: 'domainPhishingLimit',
        COUNTS_PROPERTY: 'monitoredDomainsPhishing'
      },
      {
        SCAN: 'DARK_WEB',
        PRICING_PLAN_LIMIT: 'domainDarkWebLimit',
        COUNTS_PROPERTY: 'monitoredDomainsDarkWeb'
      },
      {
        SCAN: 'BREACH',
        PRICING_PLAN_LIMIT: 'domainBreachLimit',
        COUNTS_PROPERTY: 'monitoredDomainsBreach'
      },
      {
        SCAN: 'INFO_LEAKAGE',
        PRICING_PLAN_LIMIT: 'domainInfoLeakageLimit',
        COUNTS_PROPERTY: 'monitoredDomainsInfoLeakage'
      }
    ]
  },
  [assetType.Keyword]: {
    PRICING_PLAN_LIMIT: 'keywordLimit',
    COUNTS_PROPERTY: 'monitoredKeywords',
    SCANS: [
      {
        SCAN: 'MALWARE',
        PRICING_PLAN_LIMIT: 'keywordMalwareLimit',
        COUNTS_PROPERTY: 'monitoredKeywordsMalware'
      },
      {
        SCAN: 'PHISHING',
        PRICING_PLAN_LIMIT: 'keywordPhishingLimit',
        COUNTS_PROPERTY: 'monitoredKeywordsPhishing'
      },
      {
        SCAN: 'DARK_WEB',
        PRICING_PLAN_LIMIT: 'keywordDarkWebLimit',
        COUNTS_PROPERTY: 'monitoredKeywordsDarkWeb'
      },
      {
        SCAN: 'BREACH',
        PRICING_PLAN_LIMIT: 'keywordBreachLimit',
        COUNTS_PROPERTY: 'monitoredKeywordsBreach'
      },
      {
        SCAN: 'INFO_LEAKAGE',
        PRICING_PLAN_LIMIT: 'keywordInfoLeakageLimit',
        COUNTS_PROPERTY: 'monitoredKeywordsInfoLeakage'
      }
    ]
  },
  [assetType.IPSubnet]: {
    PRICING_PLAN_LIMIT: 'ipAddressLimit', // same as IP address
    SCANS: [
      {
        SCAN: 'MALWARE',
        PRICING_PLAN_LIMIT: 'ipAddressMalwareLimit' // same as IP address
      },
      {
        SCAN: 'PHISHING',
        PRICING_PLAN_LIMIT: 'ipAddressPhishingLimit' // same as IP address
      },
      {
        SCAN: 'DARK_WEB',
        PRICING_PLAN_LIMIT: 'ipAddressDarkWebLimit' // same as IP address
      },
      {
        SCAN: 'BREACH',
        PRICING_PLAN_LIMIT: 'ipAddressBreachLimit' // same as IP address
      },
      {
        SCAN: 'INFO_LEAKAGE',
        PRICING_PLAN_LIMIT: 'ipAddressInfoLeakageLimit' // same as IP address
      }
    ]
  },
  [assetType.VIPEmail]: {
    PRICING_PLAN_LIMIT: 'vipEmailLimit',
    COUNTS_PROPERTY: 'monitoredVIPEmails',
    SCANS: [
      {
        SCAN: 'MALWARE',
        PRICING_PLAN_LIMIT: 'vipEmailMalwareLimit',
        COUNTS_PROPERTY: 'monitoredVIPEmailsMalware'
      },
      {
        SCAN: 'PHISHING',
        PRICING_PLAN_LIMIT: 'vipEmailPhishingLimit',
        COUNTS_PROPERTY: 'monitoredVIPEmailsPhishing'
      },
      {
        SCAN: 'DARK_WEB',
        PRICING_PLAN_LIMIT: 'vipEmailDarkWebLimit',
        COUNTS_PROPERTY: 'monitoredVIPEmailsDarkWeb'
      },
      {
        SCAN: 'BREACH',
        PRICING_PLAN_LIMIT: 'vipEmailBreachLimit',
        COUNTS_PROPERTY: 'monitoredVIPEmailsBreach'
      },
      {
        SCAN: 'INFO_LEAKAGE',
        PRICING_PLAN_LIMIT: 'vipEmailInfoLeakageLimit',
        COUNTS_PROPERTY: 'monitoredVIPEmailsInfoLeakage'
      }
    ]
  }
};

export const SCANS = {
  MALWARE: {
    PROPERTY: 'malwareScan',
    LABEL: 'Malware scan',
    SETTINGS_LABEL: 'Malware'
  },
  PHISHING: {
    PROPERTY: 'phishingScan',
    LABEL: 'Phishing check',
    SETTINGS_LABEL: 'Phishing'
  },
  DARK_WEB: {
    PROPERTY: 'darkWebScan',
    LABEL: 'Dark Web monitoring',
    SETTINGS_LABEL: 'Dark Web'
  },
  BREACH: {
    PROPERTY: 'breachScan',
    LABEL: 'Breach check',
    SETTINGS_LABEL: 'Breach'
  },
  INFO_LEAKAGE: {
    PROPERTY: 'infoLeakageScan',
    LABEL: 'Information leakage check',
    SETTINGS_LABEL: 'Information leakage'
  }
};
