import { createSearchParams, useNavigate } from 'react-router-dom';
import { ThreatTypeRoute } from 'src/lib/constants';
import { assetType } from 'src/models/asset';
import { threatSubtype, threatType } from 'src/models/threat';

interface customNavigationReturnValue {
  navigateToAssetDetails: (
    assetType: string,
    assetId: number,
    tabId?: number
  ) => void;
  navigateToThreatDetails: (
    type: threatType,
    subtype: threatSubtype,
    threatId: number
  ) => void;
  navigateToThreats: (assetId?: string, subAssetId?: string) => void;
  navigateToThreatTypes: (type: string, params?: object) => void;
  navigateToSettings: () => void;
  navigateToURLScan: (urlScanId: number) => void;
  navigateToTLSCertDetails: (tlsCertId: number) => void;
  navigateToActivelyExploitedCVEDetails: (
    activelyExploitedCVEId: number
  ) => void;
  navigateToThreatActorDetails: (threatActorId: number) => void;
}

export function useCustomNavigation(): customNavigationReturnValue {
  const getThreatDetailsURL = (
    threatTypeRoute: ThreatTypeRoute,
    threatId: number
  ) => {
    return `/threats/${threatTypeRoute}/${threatId}`;
  };

  const navigate = useNavigate();

  const navigateToIPDetails = (ipAddressId: number, params = {}) =>
    navigate({
      pathname: `/assets/ip/${ipAddressId}`,
      search: createSearchParams(params).toString()
    });

  const navigateToDomainDetails = (domainId: number, params = {}) =>
    navigate({
      pathname: `/assets/domain/${domainId}`,
      search: createSearchParams(params).toString()
    });

  const navigateToKeywordDetails = (keywordId: number, params = {}) =>
    navigate({
      pathname: `/assets/keyword/${keywordId}`,
      search: createSearchParams(params).toString()
    });

  const navigateToAssetDetails = (
    type: string,
    assetId: number,
    tabId: number = undefined
  ) => {
    const params = { ...(tabId ? { tabId } : {}) };
    switch (type) {
      case assetType.Domain:
        navigateToDomainDetails(assetId, params);
        break;
      case assetType.IPAdress:
        navigateToIPDetails(assetId, params);
        break;
      case assetType.Keyword:
        navigateToKeywordDetails(assetId, params);
        break;
    }
  };

  const navigateToTLSCertDetails = (tlsCertId: number) =>
    navigate({
      pathname: `/assets/tls/${tlsCertId}`
    });

  const navigateToThreatTypes = (type: string, params = {}) => {
    let pathname = '';
    switch (type) {
      case threatType.CVE:
        pathname = '/threats/cve';
        break;
      case threatType.INFORMATION_LEAKAGE:
        pathname = '/threats/information-leakage';
        break;
    }
    if (!pathname) {
      return;
    }
    navigate({
      pathname: pathname,
      search: createSearchParams({
        ...params
      }).toString()
    });
  };

  const navigateToMalwareThreatDetails = (
    subtype: threatSubtype,
    threatId: number
  ) => {
    switch (subtype) {
      case threatSubtype.POTENTIAL_MALWARE_DISTRIBUTION:
        navigate(
          getThreatDetailsURL(
            ThreatTypeRoute.POTENTIAL_MALWARE_DISTRIBUTION,
            threatId
          )
        );
        break;
      default:
        // default to malware
        navigate(getThreatDetailsURL(ThreatTypeRoute.MALWARE, threatId));
        break;
    }
  };

  const navigateToBrandThreatDetails = (
    subtype: threatSubtype,
    threatId: number
  ) => {
    switch (subtype) {
      case threatSubtype.PHISHING:
        navigate(getThreatDetailsURL(ThreatTypeRoute.PHISHING, threatId));
        break;
      case threatSubtype.DOMAIN_TAKEOVER:
        navigate(
          getThreatDetailsURL(ThreatTypeRoute.DOMAIN_TAKEOVER, threatId)
        );
        break;
    }
  };

  const navigateToPrivacyThreatDetails = (
    subtype: threatSubtype,
    threatId: number
  ) => {
    switch (subtype) {
      case threatSubtype.BREACH:
        navigate(getThreatDetailsURL(ThreatTypeRoute.BREACH, threatId));
        break;
      case threatSubtype.SENSITIVE_DATA_LEAK:
        navigate(
          getThreatDetailsURL(ThreatTypeRoute.SENSITIVE_DATA_LEAK, threatId)
        );
        break;
    }
  };

  const navigateToInfrastructureThreatDetails = (
    subType: threatSubtype,
    threatId: number
  ) => {
    switch (subType) {
      case threatSubtype.PORT_EXPOSURE:
        navigate(getThreatDetailsURL(ThreatTypeRoute.INSECURE_PORT, threatId));
        break;
      case threatSubtype.NEWLY_OPENED_PORT:
        navigate(getThreatDetailsURL(ThreatTypeRoute.INSECURE_PORT, threatId));
        break;
      case threatSubtype.TLS_CONFIGURATION:
        navigate(
          getThreatDetailsURL(ThreatTypeRoute.TLS_CERTIFICATE, threatId)
        );
        break;
      case threatSubtype.TLS_EXPIRATION:
        navigate(
          getThreatDetailsURL(ThreatTypeRoute.TLS_CERTIFICATE, threatId)
        );
        break;
      case threatSubtype.DOMAIN_EXPIRY:
        navigate(getThreatDetailsURL(ThreatTypeRoute.DOMAIN_EXPIRY, threatId));
        break;
    }
  };

  const navigateToThreatDetails = (
    type: threatType,
    subtype: threatSubtype,
    threatId: number
  ) => {
    switch (type) {
      case threatType.CVE:
        navigate(getThreatDetailsURL(ThreatTypeRoute.CVE, threatId));
        break;
      case threatType.MALWARE:
        navigateToMalwareThreatDetails(subtype, threatId);
        break;
      case threatType.BRAND:
        navigateToBrandThreatDetails(subtype, threatId);
        break;
      case threatType.INFRASTRUCTURE:
        navigateToInfrastructureThreatDetails(subtype, threatId);
        break;
      case threatType.DARK_WEB:
        navigate(getThreatDetailsURL(ThreatTypeRoute.DARK_WEB, threatId));
        break;
      case threatType.INFORMATION_LEAKAGE:
        navigateToPrivacyThreatDetails(subtype, threatId);
        break;
    }
  };

  const navigateToThreats = (
    assetId: string = undefined,
    subAssetId: string = undefined
  ) => {
    navigate({
      pathname: `/threats`,
      search: createSearchParams({
        ...(assetId && { assetId })
      }).toString()
    });
  };

  const navigateToURLScan = (urlScanId: number) =>
    navigate(`/scans/${urlScanId}`);

  const navigateToActivelyExploitedCVEDetails = (
    activelyExploitedCVEId: number
  ) => navigate(`/actively-exploited-cves/${activelyExploitedCVEId}`);

  const navigateToThreatActorDetails = (threatActorId: number) =>
    navigate(`/threat-actors/${threatActorId}`);

  const navigateToSettings = () => navigate(`/settings`);

  return {
    navigateToAssetDetails,
    navigateToThreats,
    navigateToThreatDetails,
    navigateToThreatTypes,
    navigateToSettings,
    navigateToURLScan,
    navigateToTLSCertDetails,
    navigateToActivelyExploitedCVEDetails,
    navigateToThreatActorDetails
  };
}
